.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0
}

html {
  height: 100%
}

p {
  color: grey
}

#heading {
  text-transform: uppercase;
  color: #673AB7;
  font-weight: normal
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

.form-card {
  text-align: left
}

#msform fieldset:not(:first-of-type) {
  display: none
}

#msform input,
#msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  background-color: #ECEFF1;
  font-size: 16px;
  letter-spacing: 1px
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673AB7;
  outline-width: 0
}

#msform .action-button {
  width: 100px;
  background: #673AB7;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #311B92
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000
}

.card {
  z-index: 0;
  border: none;
  position: relative
}

.fs-title {
  font-size: 25px;
  color: #673AB7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left
}

.purple-text {
  color: #673AB7;
  font-weight: normal
}

.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right
}

.fieldlabels {
  color: gray;
  text-align: left
}
.progress{
  width: 75%;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey
}

#progressbar .active {
  color: #673AB7
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "1"
  /* content: "\f13e" */
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "2"
  /* content: "\f007" */
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "3"
  /* content: "\f030" */
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c"
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar li:after {
  content: 'jkjnkk';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #673AB7
}

.progress {
  height: 20px
}

.progress-bar {
  background-color: #673AB7;
  transition: 1s ease;
  transition-delay: 10s;
}

.fit-image {
  width: 100%;
  object-fit: cover
}

.post{
  height: 140px;
  border: 1px solid green;
  margin: 6px;
  padding: 8px
}
.pdfWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}
.footer{
  display: flex;
  justify-content: space-between;
}
.ant-modal-body canvas{
  width: 100% !important;
}
.rbc-calendar {
  font-family: sans-serif;
  font-size:14px;
}
@media (max-width: 500px) {
  .post {
    height: 140px;
  }
}
@media (min-width: 800px) {
  .post {
    height: 240px;
  }
}
